.brand-one {
	.search-form {
		&__input {
			border: 2px solid $brand-one;

			@include media-breakpoint-up(md) {
				border-right: none;
			}
		}
	}
}

.brand-two {
	.search-form {
		&__input {
			border: 2px solid $brand-two;

			@include media-breakpoint-up(md) {
				border-right: none;
			}
		}
	}
}

.brand-three {
	.search-form {
		&__input {
			border: 2px solid $brand-three;

			@include media-breakpoint-up(md) {
				border-right: none;
			}
		}
	}
}

.search-form {
	display: block;
	width: 100%;

	&__input {
		vertical-align: top;
		height: 55px;
		padding: 0 10px;
		border: 2px solid $midgrey;
		margin: 0 0 10px;
		width: 100%;

		@include media-breakpoint-up(md) {
			border-right: none;
			margin: 0;
			width: calc(100% - 55px);
		}

		&:focus {
			outline: none;
		}
	}

	&__submit {
		width: 55px;
		height: 55px;
		border: 2px solid $midgrey;
		vertical-align: top;
	}

	&--white {
		.search-form__input,
		.search-form__submit {
			border-color: $white;
		}
	}
}
