.hero {
	&__slide {
		position: relative;
		width: 100%;
	}

	&__content {
		@include media-breakpoint-up(xl) {
			position: absolute;
			top: -80px;
			width: 600px;
		}
		@include media-breakpoint-up(xxl) {
			width: 800px;
		}
	}

	&__heading {
		margin-bottom: 0;
	}

	&__body {
		font-size: 24px;
		font-weight: 300;
		margin-top: 0;
	}

	&__caption {
		margin: 0;
		color: $grey;
		@include media-breakpoint-up(lg) {
			margin-left: 50%;
		}
	}

	&__link {
		display: block;
		background: rgba(255, 255, 255, 0.2);
		transition: background $transition;

		p {
			display: inline-block;
			padding: 0 20px 0 0;
			margin: 0;
			background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 14 22.34'%3E%3Cpath fill='rgba(255, 255, 255, .99)' d='M11.17 8.34L2.83 0 0 2.83l8.34 8.34L0 19.52l2.83 2.82L11.17 14 14 11.17l-2.83-2.83'/%3E%3C/svg%3E") right center / 14px 14px no-repeat;
		}

		&:hover {
			background: rgba(255, 255, 255, 0.3);
			transition: background $transition;
		}
	}

	&__pager {
		position: absolute;
		width: 100%;
		bottom: -20px;
		text-align: center;
		z-index: 150;
		@include media-breakpoint-up(lg) {
			width: 54px;
			bottom: 10px;
			right: 87.5%;
		}
		@include media-breakpoint-up(xl) {
			right: 67.5%;
		}
		@include media-breakpoint-up(xxl) {
			right: 57.5%;
		}

		li {
			width: 10px;
			height: 10px;
			background: $brand-one;
			border-radius: 50%;
			margin: 0 4px;
			cursor: pointer;

			&.cycle-pager-active {
				background: $brand-one--dark;
			}
		}
	}
}
