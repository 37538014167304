.footer {
	border-top: 2px solid $midgrey;
	color: $grey;

	h3 {
		color: $base-font-color;
		margin-top: 0;
	}

	&__logo-img {
		.logo {
			&--dark {
				fill: $brand-one--dark;
			}

			&--light {
				fill: $brand-one;
			}
		}
	}

	&__list {
		line-height: 1;
	}

	&__list-item {
		text-align: center;
		display: block !important;
		border-bottom: 1px solid $midgrey;
		margin: 0 0 4px;
		padding: 0 0 4px;

		@include media-breakpoint-up(sm) {
			text-align: left;
			display: inline-block !important;
			border-bottom: 0;
			border-right: 1px solid $midgrey;
			margin: 0 12px 0 0;
			padding: 0 12px 0 0;
		}
		@include media-breakpoint-up(xl) {
			margin: 0 18px 0 0;
			padding: 0 18px 0 0;
		}
		@include media-breakpoint-up(xxl) {
			margin: 0 24px 0 0;
			padding: 0 24px 0 0;
		}

		&:last-of-type {
			padding: 0;
			border: 0;
			margin: 0;
		}
	}

	&__list-link {
		color: $grey;
		text-transform: uppercase;
		text-decoration: none;
		font-size: 14px;
	}

	.social-icons img {
		width: 30px;
	}
}
