.find-a-therapist {
	a,
	.js-link {
		color: #006649;
	}

	.button {
		color: $white;

		&:hover {
			color: #a80532;
		}
	}

	.breadcrumbs {
		a {
			color: $white;
		}
	}

	.header {
		&__logo {
			.logo {
				&--dark {
					fill: $brand-two--dark;
				}

				&--light {
					fill: $brand-two;
				}
			}
		}

		.nav__link {
			color: $grey;
		}

		.nav__dropdown--brand-three {
			.nav__link {
				color: $brand-three;
			}
		}
	}

	.mega-menu__heading {
		a {
			color: $base-font-color;
		}
	}

	.people {
		&__image {
			.logo {
				&--dark {
					fill: $brand-two--dark;
				}
			}
		}
	}

	.pad-box {
		color: $white;
	}

	.bg--brand-one,
	.bg--brand-one--dark,
	.bg--brand-two,
	.bg--brand-two--dark,
	.bg--brand-three,
	.bg--brand-three--dark {
		a {
			color: $white;
		}
	}
}
