.button {
	display: inline-block;
	color: $white;
	font-size: 18px;
	padding: $gutter/4;
	text-align: center;
	background: $brand-one--dark;
	border: 2px solid $brand-one--dark;
	text-decoration: none;
	transition: all $transition;
	cursor: pointer;

	&:hover {
		background: $white;
		color: $brand-one--dark;
		transition: all $transition;
	}

	&--outline {
		background: transparent;

		&:hover {
			color: $white;
		}
	}

	&--block {
		display: block;
		width: 100%;
	}

	&--wide {
		padding: $gutter/4 $gutter*2;
	}

	&--brand-one {
		@include button($brand-one);
	}

	&--brand-one--dark {
		@include button($brand-one--dark);
	}

	&--brand-two {
		@include button($brand-two);
	}

	&--brand-two--dark {
		@include button($brand-two--dark);
	}

	&--brand-three {
		@include button($brand-three);
	}

	&--brand-three--dark {
		@include button($brand-three--dark);
	}

	&--brand-four {
		@include button($brand-four);
	}

	&--brand-four--dark {
		@include button($brand-four--dark);
	}

	&[disabled] {
		opacity: .1;
		cursor: default;
	}
}

.brand-one {
	.button {
		@include button($brand-one);
	}
}

.brand-one--dark {
	.button {
		@include button($brand-one--dark);
	}
}

.brand-two {
	.button {
		@include button($brand-two);
	}
}

.brand-two--dark {
	.button {
		@include button($brand-two--dark);
	}
}

.brand-three {
	.button {
		@include button($brand-three);
	}
}

.brand-three--dark {
	.button {
		@include button($brand-three--dark);
	}
}

.brand-four {
	.button {
		@include button($brand-four);
	}
}

.brand-four--dark {
	.button {
		@include button($brand-four--dark);
	}
}
