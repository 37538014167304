.membership {
	display: block;
	overflow-x: auto;
	width: 100%;
	max-width: calc(100vw - #{$gutter});

	thead {
		th {
			color: $brand-one--dark;
			font-size: 18px;
			font-weight: 500;
			padding: 10px 20px;
		}
	}

	td {
		background: $superlightgrey;
		width: 16.66667%;
		padding: 15px 30px;
		text-align: center;
		border-bottom: 2px solid $white;
		border-left: 2px solid $white;

		&:first-of-type {
			background: transparent;
			width: 33.33333%;
			text-align: left;
			border-bottom: 2px solid $superlightgrey;
			border-left: 2px solid $superlightgrey;
		}
	}

	tbody {
		tr {
			&:first-of-type {
				td {
					text-align: center;
					color: $white;
					background: rgba($brand-one--dark, .8);
					font-weight: 500;
					border-bottom: 0;

					&:first-of-type {
						background: rgba($brand-three, .75);
						border-left: 0;
					}
				}
			}
		}
	}

	tfoot {
		tr {
			td {
				color: $brand-one--dark;
				font-weight: 500;
				border-bottom: 2px solid $superlightgrey;
			}
		}
	}

	img,
	svg {
		width: 30px;
	}
}
