.border-box {
	display: block;
	text-decoration: none;
	border: 1px solid $lightgrey;
	background: $white;
	position: relative;

	&--trans {
		background: transparent;
		border: 0;
	}

	&--top {
		border-top: 3px solid;

		&.border-box--brand-one {
			border-top-color: $brand-one;

			h6 {
				color: $brand-one;
			}
		}

		&.border-box--brand-two {
			border-top-color: $brand-two;

			h6 {
				color: $brand-two;
			}
		}

		&.border-box--brand-three {
			border-top-color: $brand-three;

			h6 {
				color: $brand-three;
			}
		}

		&.border-box--brand-four {
			border-top-color: $brand-four;

			h6 {
				color: $brand-four;
			}
		}
	}

	&--bottom {
		border-bottom: 3px solid;

		&.border-box--brand-one {
			border-bottom-color: $brand-one;
		}

		&.border-box--brand-two {
			border-bottom-color: $brand-two;
		}

		&.border-box--brand-three {
			border-bottom-color: $brand-three;
		}

		&.border-box--brand-four {
			border-bottom-color: $brand-four;
		}
	}

	&--left {
		border-left: 3px solid;

		&.border-box--brand-one {
			border-left-color: $brand-one;
		}

		&.border-box--brand-two {
			border-left-color: $brand-two;
		}

		&.border-box--brand-three {
			border-left-color: $brand-three;
		}

		&.border-box--brand-four {
			border-left-color: $brand-four;
		}
	}

	&--right {
		border-right: 3px solid;

		&.border-box--brand-one {
			border-right-color: $brand-one;
		}

		&.border-box--brand-two {
			border-right-color: $brand-two;
		}

		&.border-box--brand-three {
			border-right-color: $brand-three;
		}

		&.border-box--brand-four {
			border-right-color: $brand-four;
		}
	}

	&--full {
		border: 1px solid;

		&.border-box--brand-one {
			border-color: $brand-one;
		}

		&.border-box--brand-two {
			border-color: $brand-two;
		}

		&.border-box--brand-three {
			border-color: $brand-three;
		}

		&.border-box--brand-four {
			border-color: $brand-four;
		}
	}

	&--notice {
		&.border-box--brand-one {
			p {
				color: $brand-one;
			}
		}

		&.border-box--brand-two {
			p {
				color: $brand-two;
			}
		}

		&.border-box--brand-three {
			p {
				color: $brand-three;
			}
		}

		&.border-box--brand-four {
			p {
				color: $brand-four;
			}
		}
	}

	&--sticky {
		border: 1px solid;
		border-top: 3px solid;

		&.border-box--brand-one {
			border-color: $brand-one;
		}

		&.border-box--brand-two {
			border-color: $brand-two;
		}

		&.border-box--brand-three {
			border-color: $brand-three;
		}

		&.border-box--brand-four {
			border-color: $brand-four;
		}
	}

	&--relative {
		position: relative;
	}

	&__image {
		position: relative;

		img {
			display: block;
		}
	}

	&__image-overlay {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .5);
		color: $white;
		text-decoration: none;
		opacity: 0;
		transition: opacity $transition;
		display: none;

		@include media-breakpoint-up(lg) {
			display: block;

			&:hover {
				opacity: 1;
				transition: opacity $transition;
			}
		}

		p {
			margin: 0;
		}
	}
	@include media-breakpoint-up(lg) {
		&:hover {
			.border-box__image-overlay {
				opacity: 1;
				transition: opacity $transition;
			}
		}
	}

	&__valign {
		position: absolute;
		padding: $gutter;
		width: 100%;
		top: 50%;
		transform: translateY(-50%);
	}

	&__content {
		padding: $gutter/2;
		@include media-breakpoint-up(lg) {
			padding: $gutter;
		}

		&--thin {
			padding: $gutter/2;
		}

		&--flush {
			padding: 0;
		}

		p {
			color: $grey;
			font-size: 18px;
		}

		.border-box__date {
			color: $white;
		}
	}

	&__button {
		display: block;
	}

	&__title,
	&__subtitle {
		margin: 0;
	}

	&__date {
		background: $brand-one--dark;
		position: absolute;
		top: 0;
		right: 30px;
		margin: 0;
		padding: 10px;
	}

	&__heading {
		margin: 0 0 10px;
		color: $brand-one;
	}

	&__icon {
		float: left;
		margin: 0 10px 0 0;
	}

	&__underline {
		position: relative;

		&::after {
			content: '';
			position: absolute;
			display: block;
			bottom: -5px;
			width: 0;
			height: 3px;
			transition: width $transition;
		}
	}

	&:hover {
		.border-box__underline {
			&::after {
				width: 100%;
				transition: width $transition;
			}
		}
	}

	&.border-box--brand-one {
		.border-box__underline {
			color: $brand-one;

			&::after {
				background: $brand-one--dark;
			}
		}

		&:hover {
			.border-box__underline {
				color: $brand-one--dark;
			}
		}
	}

	&.border-box--brand-two {
		.border-box__underline {
			color: $brand-two;

			&::after {
				background: $brand-two--dark;
			}
		}

		&:hover {
			.border-box__underline {
				color: $brand-two--dark;
			}
		}
	}

	&.border-box--brand-three {
		.border-box__underline {
			color: $brand-three;

			&::after {
				background: $brand-three--dark;
			}
		}

		&:hover {
			.border-box__underline {
				color: $brand-three--dark;
			}
		}
	}
}
