.dropdown {
	background: $brand-one;

	&__nav {
		border-left: 1px solid $brand-one--dark;
	}

	&__item {
		position: relative;
		border-right: 1px solid $brand-one--dark;

		&:hover {
			.dropdown__list {
				display: block;
			}
		}
	}

	&__link {
		color: $white;
		padding: $gutter/4 $gutter;
		display: inline-block;
		text-decoration: none;

		&--child {
			padding: $gutter/4 $gutter*1.5 $gutter/4 $gutter;
		}

		&:hover {
			background: $brand-one--dark;
		}
	}

	&__list {
		width: 100%;
		position: absolute;
		top: 100%;
		left: 0;
		background: $brand-one--dark;
		padding: $gutter/2;
		display: none;
	}

	&__list-link {
		color: $white;
		display: block;
		text-decoration: none;
	}
}
