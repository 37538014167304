.people {
	position: relative;
	border-bottom: 1px solid $grey;

	&.columns {
		margin: 0 -#{$gutter/2} $gutter;
	}

	&__image {
		.logo {
			&--dark {
				fill: $brand-one--dark;
			}
		}
	}

	&__title,
	&__role {
		margin: 0;
		font-weight: 300;
	}

	&__title {
		margin: 0 0 $gutter/2;
	}

	&__role {
		font-size: 75%;
	}

	p {
		margin: 0 0 1rem 0;

		&.people__content {
			margin: 0 0 $gutter/4;
		}
	}
}
