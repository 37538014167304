html {
	color: $base-font-color;
	font-family: $sans-serif;
	font-size: $base-font-size;
	font-weight: $base-font-weight;
	line-height: $base-line-height;
	overflow-x: hidden;
	-webkit-font-smoothing: antialiased;
}

a,
.js-link {
	color: $brand-one--dark;

	&:hover {
		text-decoration: none;
	}
}

.brand-one {
	a {
		color: $brand-one--dark;
	}
}

.brand-two {
	a {
		color: $brand-two--dark;
	}
}

.brand-three {
	a {
		color: $brand-three--dark;
	}
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: 400;
}

h1 {
	@include resp-font-size(34);

	margin-bottom: 0;
	font-weight: 800;
}

h2 {
	@include resp-font-size(29);
}

h3 {
	@include resp-font-size(24);

	margin-top: 0;
}

h4 {
	@include resp-font-size(20);
}

h5 {
	@include resp-font-size(18);
}

h6 {
	margin: 0;
	text-transform: uppercase;
	@include resp-font-size(16);
}

.heading {
	&__icon {
		position: relative;
		width: 40px;
		top: 12px;

		path {
			fill: $brand-one;
		}
	}

	&--brand-one {
		color: $brand-one--dark;

		a,
		&__link {
			color: $brand-one--dark;
		}

		.heading__icon {
			path {
				fill: $brand-one;
			}
		}
	}

	&--brand-two {
		color: $brand-two--dark;

		a,
		&__link {
			color: $brand-two--dark;
		}

		.heading__icon {
			path {
				fill: $brand-two;
			}
		}
	}

	&--brand-three {
		color: $brand-three--dark;

		a,
		&__link {
			color: $brand-three--dark;
		}

		.heading__icon {
			path {
				fill: $brand-three;
			}
		}
	}

	&--light {
		font-weight: 100;
		margin-top: 0;
	}

	&__link {
		text-decoration: none;
	}
}

.table {
	width: 100%;
	margin: $gutter/2 0;
	border-top: 1px solid $lightgrey;

	&__cell {
		padding: $gutter/4 0;
		border-bottom: 1px solid $lightgrey;
		min-width: 90px;
	}

	&--matrix {
		border: 0;
		width: 95%;
		margin: $gutter/2 $gutter $gutter/2 0;
	}

	@include media-breakpoint-down(sm) {
		&--responsive {
			tr {
				&:first-of-type {
					display: none;
				}
			}

			td {
				display: block;
				position: relative;
				padding-top: 30px;
				transition: padding 500ms;

				input {
					background: transparent;
				}

				&::before {
					content: attr(title);
					position: absolute;
					left: 0;
					top: 16px;
					transform: translateY(-50%);
					z-index: -1;
					transition: top 500ms;
				}

				&.table-cell--active {
					padding: 55px 0 0;
					transition: padding 500ms;

					&::before {
						top: 25%;
						transition: top 500ms;
					}
				}
			}

			.td-action {
				height: 50px;
			}
		}
	}
}

.lead {
	font-size: 18px;
}

.small {
	font-size: 14px;
}

.meta {
	color: $grey;

	&__author {
		margin: 0 $gutter/2 0 0;
	}

	&__date {
		margin: 0 $gutter/2 0 0;
	}

	&__category {
		text-transform: uppercase;
		text-decoration: none;
	}
}

.error {
	font-weight: 700;
	color: $brand-one !important;
}
