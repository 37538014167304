.mega-menu {
	width: 100%;
	position: absolute;
	left: 0;
	right: 0;
	display: none;
	z-index: 50000;
	@include media-breakpoint-up(lg) {
		top: 83%;
	}
	@include media-breakpoint-up(xl) {
		top: 86%;
	}

	&--visible {
		display: block;
	}

	&__heading {
		margin: 0 0 $gutter/4;

		&--active {
			.mega-menu__link--heading {
				position: relative;

				&::after {
					content: '';
					position: absolute;
					display: block;
					bottom: -5px;
					width: 100%;
					height: 3px;
					transition: width $transition;
				}
			}
		}
	}

	&__link {
		text-decoration: none;
		padding: 0 0 2px;
		border-bottom: 1px solid transparent;
		transition: color $transition, border-bottom $transition;

		&:hover,
		&--active {
			transition: color $transition, border-bottom $transition;
			padding: 0 0 2px;
			border-bottom: 1px solid;
		}

		&--heading {
			position: relative;
			padding: 0;
			border-bottom: 0;

			&::after {
				content: '';
				position: absolute;
				display: block;
				bottom: -5px;
				width: 0;
				height: 3px;
				transition: width $transition;
			}

			&:hover {
				padding: 0;
				border-bottom: 0;

				&::after {
					width: 100%;
					transition: width $transition;
				}
			}
		}
	}

	&.border-box--brand-one {
		.mega-menu__heading {
			&--active {
				.mega-menu__link--heading {
					color: $brand-one--dark;
				}
			}
		}

		.mega-menu__link {
			color: $brand-one;

			&--active,
			&:hover {
				color: $brand-one--dark;
			}

			&::after {
				background: $brand-one--dark;
			}
		}
	}

	&.border-box--brand-two {
		.mega-menu__heading {
			&--active {
				.mega-menu__link--heading {
					color: $brand-two--dark;
				}
			}
		}

		.mega-menu__link {
			color: $brand-two;

			&--active,
			&:hover {
				color: $brand-two--dark;
			}

			&::after {
				background: $brand-two--dark;
			}
		}
	}

	&.border-box--brand-three {
		.mega-menu__heading {
			&--active {
				.mega-menu__link--heading {
					color: $brand-three--dark;
				}
			}
		}

		.mega-menu__link {
			color: $brand-three;

			&--active,
			&:hover {
				color: $brand-three--dark;
			}

			&::after {
				background: $brand-three--dark;
			}
		}
	}

	&__overlay {
		z-index: 100;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		height: 100%;
		background: rgba(0, 0, 0, .5);
		display: none;
	}

	p {
		text-transform: none;
	}

	.list__item--spaced {
		font-weight: 400;
	}
}
