/* Sass Variables */

/* Breakpoints */
$grid-breakpoints: (
	xs: 0,
	sm: 568px,
	md: 768px,
	lg: 992px,
	xl: 1280px,
	xxl: 1500px
) !default;

/* Container widths */
$container-max-widths: (
	sm: 520px,
	md: 720px,
	lg: 940px,
	xl: 1220px,
	xxl: 1420px
) !default;

$gutter: 40px;

/* Font sizes */
$base-font-size: 16px;
$base-line-height: 1.6;
$base-font-weight: 300;

$transition: 500ms;

// Fonts
$sans-serif: 'Montserrat', 'Helvetica Neue', Helvetica, Arial, sans-serif;
$serif: 'Times New Roman', serif;

/* Colours */
$base-font-color: #4a4a4a;

$white: #fff;
$black: #000;
$darkgrey: #333;
$grey: #8a8a8a;
$midgrey: #d6d6d6;
$lightgrey: #eee;
$superlightgrey: #f8f8f8;

$brand-one: #a80532;
$brand-one--dark: #700c24;
$brand-two: #009170;
$brand-two--dark: #015744;
$brand-three: #f5a623;
$brand-three--dark: #d98f14;
$brand-four: #c90;
$brand-four--dark: darken($brand-three, 5%);
