.matrix {
	&__row {
		margin: 0 20px 5px 0;
		position: relative;

		@include media-breakpoint-down(sm) {
			top: 25px;
		}

		@include media-breakpoint-up(lg) {
			margin: 0 0 5px;
		}

		.form__text,
		.form__select {
			margin: 0 0 2px;
			@include media-breakpoint-up(lg) {
				margin: 0;
			}
		}
	}

	&__remove {
		position: absolute;
		right: -20px;
		top: 50%;
		transform: translateY(-50%);
		color: red;
		cursor: pointer;
		font-size: 30px;
	}
}
