*,
*::before,
*::after {
	box-sizing: border-box;
}

body {
	display: flex;
	min-height: 100vh;
	flex-direction: column;
}

.container {
	margin: 0 auto;
	@include make-container();
}
