/* Bootstrap media queries
/* Breakpoint min/max are set in the _vars.scss file

/* Mixin: Media Min Width
Usage:
.foo {
	@include media-breakpoint-up(sm) {
		color: #f00;
	}
}
*/
@mixin media-breakpoint-up($name, $breakpoints: $grid-breakpoints) {
	$min: breakpoint-min($name, $breakpoints);
	@if $min {
		@media (min-width: $min) {
			@content;
		}
	}
	@else {
		@content;
	}
}

/* Mixin: Media Max Width
Usage:
.foo {
	@include media-breakpoint-down(md) {
		color: #f00;
	}
}
*/
@mixin media-breakpoint-down($name, $breakpoints: $grid-breakpoints) {
	$max: breakpoint-max($name, $breakpoints);
	@if $max {
		@media (max-width: $max) {
			@content;
		}
	}
	@else {
		@content;
	}
}

/* Mixin: Media Single Breakpoint
Usage:
.foo {
	@include media-breakpoint-only(md) {
		color: #f00;
	}
}
*/
@mixin media-breakpoint-only($name, $breakpoints: $grid-breakpoints) {
	@include media-breakpoint-up($name, $breakpoints) {
		@include media-breakpoint-down($name, $breakpoints) {
			@content;
		}
	}
}

/* Mixin: Media Between Breakpoints
Usage:
.foo {
	@include media-breakpoint-between(sm, lg) {
		color: #f00;
	}
}
*/
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $grid-breakpoints) {
	@include media-breakpoint-up($lower, $breakpoints) {
		@include media-breakpoint-down($upper, $breakpoints) {
			@content;
		}
	}
}

/* Mixin for font declaration syntax
Usage: @include declare-font-face('Gotham', 'gotham');
*/
@mixin declare-font-face($font-family, $font-filename, $font-weight: normal, $font-style: normal, $font-stretch: normal) {
	@font-face {
		font-family: '#{$font-family}';
		src: url('../fonts/#{$font-filename}.woff2') format('woff2'),
			url('../fonts/#{$font-filename}.woff') format('woff');
		font-weight: $font-weight;
		font-style: $font-style;
		font-stretch: $font-stretch;
	}
}

/* Mixin: Font size
Usage: 	@include font-size(14);
		@include font-size(32px);
*/
@mixin font-size($size) {
	@if unitless($size) {
		$size: $size * 1px;
	}
	font-size: $size;
	font-size: ($size / $base-font-size) * 1rem;
}

/* Mixin: Font face
Usage: @include font-face('gotham', '/fonts/gotham');
*/
@mixin font-face($font-name, $file-name, $weight: normal, $style: normal) {
	@font-face {
		font-family: quote($font-name);
		src: url($file-name + '.eot');
		src: url($file-name + '.eot?#iefix')  format('embedded-opentype'),
			url($file-name + '.woff') format('woff'),
			url($file-name + '.ttf')  format('truetype'),
			url($file-name + '.svg##{$font-name}')  format('svg');
		font-weight: $weight;
		font-style: $style;
	}
}

/* Mixin: Responsive font sizing
Usage:
// Sets every breakpoint to have a different font size
.foo {
	@include resp-font-size(22, 24, 28, 36, 48);
}

// Sets xs, sm and md to have different font sizes,
// lg and xl will then inherit the md size
.bar {
	@include resp-font-size(18, 20, 24)
}
*/
/* stylelint-disable */
@mixin resp-font-size($xs, $sm: $xs, $md: $sm, $lg: $md, $xl: $lg, $xxl: $xl) {
	@each $break, $size in (xs: $xs, sm: $sm, md: $md, lg: $lg, xl: $xl, xxl: $xxl) {
		@if unitless($size) {
			$size: $size * 1px;
		}
		@include media-breakpoint-up($break) {
			font-size: $size;
		}
	}
}
/* stylelint-enable */

/* Mixin: Container breakpoints
Usage:
.container {
	@include make-container();
}
*/
@mixin make-container() {
	@each $break, $width in (sm: map-get($container-max-widths, sm), md: map-get($container-max-widths, md), lg: map-get($container-max-widths, lg), xl: map-get($container-max-widths, xl), xxl: map-get($container-max-widths, xxl)) {
		@include media-breakpoint-up($break) {
			width: $width;
		}
	}
}

/* Mixin: Grid creation
Usage:
.columns {
	@include make-grid();
}
Todo:
Better scalability
*/
@mixin make-grid() {
	margin: 0 0 -#{$gutter};
	font-size: 0;
	@include media-breakpoint-up(sm) {
		margin: 0 -#{$gutter/2} -#{$gutter};
	}

	&__column {
		width: 100%;
		vertical-align: top;
		display: inline-block;
		font-size: $base-font-size;
		padding: 0 $gutter/2;
		margin: 0 0 $gutter;
	}

	&--two {
		> .columns__column {
			width: 50%;

			&--double {
				width: 100%;
			}
		}
	}

	&--two-thirds {
		> .columns__column {
			width: 33.33333%;

			&:nth-of-type(2n+1) {
				width: 66.66666%;
			}
		}
	}

	&--two-thirds-swap {
		> .columns__column {
			width: 66.66666%;

			&:nth-of-type(2n+1) {
				width: 33.33333%;
			}
		}
	}

	&--two-quarters {
		> .columns__column {
			width: 25%;

			&:nth-of-type(2n+1) {
				width: 75%;
			}

			&--double {
				width: 50%;
			}
		}
	}

	&--two-quarters-swap {
		> .columns__column {
			width: 75%;

			&:nth-of-type(2n+1) {
				width: 25%;
			}
		}
	}

	&--two-fifths {
		> .columns__column {
			width: 20%;

			&:nth-of-type(2n+1) {
				width: 80%;
			}

			&--double {
				width: 40%;
			}
		}
	}

	&--two-fifths-swap {
		> .columns__column {
			width: 80%;

			&:nth-of-type(2n+1) {
				width: 20%;
			}
		}
	}

	&--three {
		> .columns__column {
			width: 33.33333%;

			&--double {
				width: 66.66666%;
			}
		}
	}

	&--three-offset {
		> .columns__column {
			width: 25%;

			&:nth-of-type(3n+1) {
				width: 50%;
			}
		}
	}

	&--three-offset-swap {
		> .columns__column {
			width: 25%;

			&:nth-of-type(3n+3) {
				width: 50%;
			}
		}
	}

	&--three-wide {
		> .columns__column {
			width: 50%;

			&:nth-of-type(3n+1),
			&:nth-of-type(3n+3) {
				width: 25%;
			}
		}
	}

	&--four {
		> .columns__column {
			width: 25%;

			&--double {
				width: 50%;
			}
		}
	}

	&--five {
		> .columns__column {
			width: 20%;

			&--double {
				width: 40%;
			}
		}
	}

	&--six {
		> .columns__column {
			width: 16.66667%;

			&--double {
				width: 33.33333%;
			}
		}
	}

	@each $break, $width in (sm: map-get($container-max-widths, sm), md: map-get($container-max-widths, md), lg: map-get($container-max-widths, lg), xl: map-get($container-max-widths, xl), xxl: map-get($container-max-widths, xxl)) {
		@include media-breakpoint-up($break) {
			&--two-#{$break} {
				> .columns__column {
					width: 50%;

					&--double {
						width: 100%;
					}
				}
			}

			&--two-thirds-#{$break} {
				> .columns__column {
					width: 33.33333%;

					&:nth-of-type(2n+1) {
						width: 66.66666%;
					}
				}
			}

			&--two-thirds-swap-#{$break} {
				> .columns__column {
					width: 66.66666%;

					&:nth-of-type(2n+1) {
						width: 33.33333%;
					}
				}
			}

			&--two-quarters-#{$break} {
				> .columns__column {
					width: 25%;

					&:nth-of-type(2n+1) {
						width: 75%;
					}

					&--double {
						width: 50%;
					}
				}
			}

			&--two-quarters-swap-#{$break} {
				> .columns__column {
					width: 75%;

					&:nth-of-type(2n+1) {
						width: 25%;
					}
				}
			}

			&--two-fifths-#{$break} {
				> .columns__column {
					width: 20%;

					&:nth-of-type(2n+1) {
						width: 80%;
					}

					&--double {
						width: 40%;
					}
				}
			}

			&--two-fifths-swap-#{$break} {
				> .columns__column {
					width: 80%;

					&:nth-of-type(2n+1) {
						width: 20%;
					}
				}
			}

			&--three-#{$break} {
				> .columns__column {
					width: 33.33333%;

					&--double {
						width: 66.66666%;
					}
				}
			}

			&--three-offset-#{$break} {
				> .columns__column {
					width: 25%;

					&:nth-of-type(3n+1) {
						width: 50%;
					}
				}
			}

			&--three-offset-swap-#{$break} {
				> .columns__column {
					width: 25%;

					&:nth-of-type(3n+3) {
						width: 50%;
					}
				}
			}

			&--three-wide-#{$break} {
				> .columns__column {
					width: 50%;

					&:nth-of-type(3n+1),
					&:nth-of-type(3n+3) {
						width: 25%;
					}
				}
			}

			&--four-#{$break} {
				> .columns__column {
					width: 25%;

					&--double {
						width: 50%;
					}
				}
			}

			&--five-#{$break} {
				> .columns__column {
					width: 20%;

					&--double {
						width: 40%;
					}
				}
			}

			&--six-#{$break} {
				> .columns__column {
					width: 16.66667%;

					&--double {
						width: 33.33333%;
					}
				}
			}
		}
	}
}

/* Mixin: Hiding helper
Usage:
.hidden {
	@include hidden();
}
*/
@mixin hidden() {
	@each $break, $width in (sm: map-get($container-max-widths, sm), md: map-get($container-max-widths, md), lg: map-get($container-max-widths, lg), xl: map-get($container-max-widths, xl), xxl: map-get($container-max-widths, xxl)) {
		@include media-breakpoint-only($break) {
			&--#{$break} {
				display: none !important;
			}
		}

		@include media-breakpoint-up($break) {
			&--#{$break}-up {
				display: none !important;
			}
		}

		@include media-breakpoint-down($break) {
			&--#{$break}-down {
				display: none !important;
			}
		}
	}
}

/* Mixin: Text align helper
Usage:
.text {
	@include text-align();
}
*/
@mixin text-align() {
	&--left {
		text-align: left !important;
	}

	&--right {
		text-align: right !important;
	}

	&--center {
		text-align: center !important;
	}

	@each $break, $width in (sm: map-get($container-max-widths, sm), md: map-get($container-max-widths, md), lg: map-get($container-max-widths, lg), xl: map-get($container-max-widths, xl), xxl: map-get($container-max-widths, xxl)) {
		@include media-breakpoint-only($break) {
			&--left--#{$break} {
				text-align: left !important;
			}

			&--right--#{$break} {
				text-align: right !important;
			}

			&--center--#{$break} {
				text-align: center !important;
			}
		}

		@include media-breakpoint-up($break) {
			&--left--#{$break}-up {
				text-align: left !important;
			}

			&--right--#{$break}-up {
				text-align: right !important;
			}

			&--center--#{$break}-up {
				text-align: center !important;
			}
		}

		@include media-breakpoint-down($break) {
			&--left--#{$break}-down {
				text-align: left !important;
			}

			&--right--#{$break}-down {
				text-align: right !important;
			}

			&--center--#{$break}-down {
				text-align: center !important;
			}
		}
	}
}

@mixin button($background) {
	background: #{$background};
	border: 2px solid #{$background};

	&:hover {
		background: $white;
		color: #{$background};

		&[disabled] {
			background: #{$background};
			color: $white;
		}
	}

	&.button--outline {
		background: transparent;
		color: $black;

		&:hover {
			background: #{$background};
			color: $white;
		}
	}
}

@mixin pad() {
	@for $i from 1 through 20 {
		$padding: ($gutter/2)*$i;

		&--#{$i} {
			padding: $padding !important;
		}

		&--v--#{$i} {
			padding-top: $padding !important;
			padding-bottom: $padding !important;
		}

		&--t--#{$i} {
			padding-top: $padding !important;
		}

		&--b--#{$i} {
			padding-bottom: $padding !important;
		}

		&--h--#{$i} {
			padding-left: $padding !important;
			padding-right: $padding !important;
		}

		&--l--#{$i} {
			padding-left: $padding !important;
		}

		&--r--#{$i} {
			padding-right: $padding !important;
		}

		@each $break, $width in (sm: map-get($container-max-widths, sm), md: map-get($container-max-widths, md), lg: map-get($container-max-widths, lg), xl: map-get($container-max-widths, xl), xxl: map-get($container-max-widths, xxl)) {
			@include media-breakpoint-up($break) {
				&--#{$i}-#{$break} {
					padding: $padding !important;
				}

				&--v--#{$i}-#{$break} {
					padding-top: $padding !important;
					padding-bottom: $padding !important;
				}

				&--t--#{$i}-#{$break} {
					padding-top: $padding !important;
				}

				&--b--#{$i}-#{$break} {
					padding-bottom: $padding !important;
				}

				&--h--#{$i}-#{$break} {
					padding-left: $padding !important;
					padding-right: $padding !important;
				}

				&--l--#{$i}-#{$break} {
					padding-left: $padding !important;
				}

				&--r--#{$i}-#{$break} {
					padding-right: $padding !important;
				}
			}
		}
	}
}

@mixin push() {
	margin: 0;

	@for $i from 1 through 20 {
		$margin: ($gutter/2)*$i;

		&--#{$i} {
			margin: $margin !important;
		}

		&--v--#{$i} {
			margin-top: $margin !important;
			margin-bottom: $margin !important;
		}

		&--t--#{$i} {
			margin-top: $margin !important;
		}

		&--b--#{$i} {
			margin-bottom: $margin !important;
		}

		&--h--#{$i} {
			margin-left: $margin !important;
			margin-right: $margin !important;
		}

		&--l--#{$i} {
			margin-left: $margin !important;
		}

		&--r--#{$i} {
			margin-right: $margin !important;
		}

		@each $break, $width in (sm: map-get($container-max-widths, sm), md: map-get($container-max-widths, md), lg: map-get($container-max-widths, lg), xl: map-get($container-max-widths, xl), xxl: map-get($container-max-widths, xxl)) {
			@include media-breakpoint-up($break) {
				&--#{$i}-#{$break} {
					margin: $margin !important;
				}

				&--v--#{$i}-#{$break} {
					margin-top: $margin !important;
					margin-bottom: $margin !important;
				}

				&--t--#{$i}-#{$break} {
					margin-top: $margin !important;
				}

				&--b--#{$i}-#{$break} {
					margin-bottom: $margin !important;
				}

				&--h--#{$i}-#{$break} {
					margin-left: $margin !important;
					margin-right: $margin !important;
				}

				&--l--#{$i}-#{$break} {
					margin-left: $margin !important;
				}

				&--r--#{$i}-#{$break} {
					margin-right: $margin !important;
				}
			}
		}
	}
}

@mixin pull() {
	@for $i from 1 through 20 {
		$margin: ($gutter/2)*$i;

		&--#{$i} {
			margin: -#{$margin} !important;
		}

		&--v--#{$i} {
			margin-top: -#{$margin} !important;
			margin-bottom: -#{$margin} !important;
		}

		&--t--#{$i} {
			margin-top: -#{$margin} !important;
		}

		&--b--#{$i} {
			margin-bottom: -#{$margin} !important;
		}

		&--h--#{$i} {
			margin-left: -#{$margin} !important;
			margin-right: -#{$margin} !important;
		}

		&--l--#{$i} {
			margin-left: -#{$margin} !important;
		}

		&--r--#{$i} {
			margin-right: -#{$margin} !important;
		}

		@each $break, $width in (sm: map-get($container-max-widths, sm), md: map-get($container-max-widths, md), lg: map-get($container-max-widths, lg), xl: map-get($container-max-widths, xl), xxl: map-get($container-max-widths, xxl)) {
			@include media-breakpoint-up($break) {
				&--#{$i}-#{$break} {
					margin: -#{$margin} !important;
				}

				&--v--#{$i}-#{$break} {
					margin-top: -#{$margin} !important;
					margin-bottom: -#{$margin} !important;
				}

				&--t--#{$i}-#{$break} {
					margin-top: -#{$margin} !important;
				}

				&--b--#{$i}-#{$break} {
					margin-bottom: -#{$margin} !important;
				}

				&--h--#{$i}-#{$break} {
					margin-left: -#{$margin} !important;
					margin-right: -#{$margin} !important;
				}

				&--l--#{$i}-#{$break} {
					margin-left: -#{$margin} !important;
				}

				&--r--#{$i}-#{$break} {
					margin-right: -#{$margin} !important;
				}
			}
		}
	}
}
