.flash-message {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
	width: 80%;
	color: black;
	z-index: 999;
	color: $base-font-color;
	background: $white;
	line-height: 1;
	border: 1px solid $brand-two;
	border-bottom: 3px solid $brand-two;
	border-top: none;

	&__content {
		position: relative;
		padding: 1rem 2rem;

		p:first-of-type{
			margin-top: 0;
		}

		p:last-of-type {
			margin-bottom: 0;
		}
	}

	&__close {
		position: absolute;
		top: 0;
		right: 0;
		padding: 0.5rem;
		font-size: 30px;
		cursor: pointer;
	}
}
