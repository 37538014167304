.columns {
	@include make-grid();

	@include media-breakpoint-down(sm) {
		.columns {
			margin: 0 -#{$gutter/2} -#{$gutter};
		}
	}

	&--gutterless {
		margin: 0 0 -#{$gutter};

		> .columns__column {
			padding: 0;
			margin: 0 0 $gutter;
		}
	}

	&--gutter-xs {
		margin: 0 -#{$gutter/10};

		> .columns__column {
			padding: 0 $gutter/10;
			margin: 0;
		}
	}

	&--rel {
		position: relative;
	}

	&__column {
		&--rel {
			position: relative;
		}
	}

	&--center {
		> .columns__column {
			vertical-align: middle;
		}
	}

	&--border {
		> .columns__column {
			border-right: 2px solid $lightgrey;

			@include media-breakpoint-down(xs) {
				&:nth-child(2n) {
					border: 0;
				}
			}

			&:last-child {
				border: 0;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		&--swap {
			direction: rtl;

			> .columns__column {
				direction: ltr;
			}
		}
	}
}
