$resources-b-1: 1000px;
$resources-b-2: 540px;

.resources {
	padding: 0 1rem;

	&--graduate {
		.resources__search,
		.resources__cats {
			display: none;
		}

		.resources__listing {
			width: 100%;
			justify-content: flex-start;
			gap: 2.5rem;
		}

		.resources__listing-item {
			margin-bottom: 0;
			width: calc(50% - 1.25rem);

			@media (min-width: $resources-b-1) {
				width: calc(33.33% - 2rem);
			}
		}
	}

	&__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-start;
	}

	&__intro {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		margin-bottom: 3rem;

		h1 {
			display: block;
			font-size: 33px;
			font-weight: 100;
			color: $brand-three;
			line-height: 1.3;
			width: 100%;

			@media (min-width: $resources-b-1) {
				margin: 0 2rem 0 0;
				width: unset;
			}
		}

		p {
			width: 100%;
			display: inline-block;
			margin: 0;
			font-size: 18px;
		}
	}

	&__search {
		margin-top: 1rem;
		width: 100%;
		max-width: 400px;

		@media (min-width: $resources-b-1) {
			max-width: 220px;
			margin-top: 3px;
		}
	}

	&__search-input {
		height: 50px;
		border: 1px solid $brand-three;
		padding: 0 10px;
		border-radius: 0;
		margin-right: -5px;
		width: calc(100% - 50px);
	}

	&__search-button {
		height: 50px;
		aspect-ratio: 1;
		appearance: none;
		cursor: pointer;
		border: 1px solid $brand-three;
		background-color: $brand-three;
		color: #fff;
	}

	&__cats {
		width: 100%;
		background-color: #F8F8F8;
		padding: 18px 24px;

		@media (min-width: $resources-b-1) {
			width: calc(33.33% - 1.25rem);
		}

		ul {
			list-style-type: none;
			margin: 0;
			padding: 0;
		}

		&:has(.resources__cats-toggle input:checked) {
			ul {
				@media (max-width: $resources-b-1) {
					display: none;
				}
			}
		}
	}

	&__cats-toggle {
		font-size: 18px;
		width: 100%;
		display: inline-block;
		text-align: left;
		position: relative;
		border-bottom: 1px solid;
		padding: 0 0 14px;
		margin: 0 0 11px;

		&::after {
			content: "";
			position: absolute;
			top: 0.5rem;
			right: 0;
			width: 0.7rem;
			aspect-ratio: 1;
			box-shadow: inset -2px -2px;
			transform: rotate(-135deg);
		}

		input {
			position: absolute;
			opacity: 0;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}

		&:has(input:checked) {
			border-bottom: unset;
			padding: 0;
			margin: 0;

			&::after {
				transform: rotate(45deg);
			}
		}

		@media (min-width: $resources-b-1) {
			display: none;
		}
	}

	&__cats-item {
		&:not(:last-of-type) {
			border-bottom: 1px solid;
			padding-bottom: 14px;
			margin-bottom: 11px;
		}

		a {
			font-size: 18px;
			color: #4A4A49 !important;
			text-decoration: none;
		}

		&--active {
			font-weight: 800;
		}
	}

	&__listing {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		list-style-type: none;
		margin: 3rem 0 0;
		padding: 0;

		@media (min-width: $resources-b-1) {
			width: calc(66.66% - 1.25rem);
			margin: 0;
		}

		&--wide {
			justify-content: flex-start;
			gap: 2.5rem;

			@media (min-width: $resources-b-1) {
				width: 100%;
			}

			.resources__listing-item {
				margin-bottom: 0;
				width: calc(50% - 1.25rem);

				@media (min-width: $resources-b-1) {
					width: calc(33.33% - 2rem);
				}
			}
		}	
	}


	&__listing-item {
		width: 100%;
		padding-left: 1rem;
		border-left: 3px solid $brand-three;
		margin-bottom: 50px;
		height: fit-content;
		
		@media (min-width: $resources-b-2) {
			width: calc(50% - 1.25rem);
		}
		
	}

	&__listing-link {
		text-decoration: none;
		color: #4A4A49 !important;
	}

	&__listing-image {
		display: block;
		width: 100%;
	}

	&__listing-heading {
		margin: 0;
		font-size: 24px;
		margin-top: 1.1ex;
	}

	&__listing-intro {
		margin: 0;
		font-size: 16px;
		margin-top: 1.3ex;
	}

	&__listing-link-text {
		margin: 0;
		font-size: 16px;
		color: $brand-three;
		margin-top: 1.7ex;
		position: relative;
		display: inline-block;

		&::after {
			content: "";
			position: absolute;
			top: 0.5rem;
			right: -1.1rem;
			width: 0.7rem;
			aspect-ratio: 1;
			box-shadow: inset -2px -2px $brand-three;
			transform: rotate(-45deg);
		}
	}

}

.resources-entry {
	&__container {
		max-width: 800px;
		background-color: #F8F8F8;
		padding: 1.7rem;
	}

	&__content {
		background-color: #fff;
		margin-top: 1.2rem;
		padding: 1rem 1.7rem 1.7rem;
	}

	&__image {
		margin: -1.7rem -1.7rem 0;
		width: calc(100% + 3.4rem);
		max-width: unset;
	}

	&__heading {
		margin: 0;
		color: $brand-three;
		font-weight: 100;
		font-size: 33px;
	}

	&__copy {
		font-size: 18px;
		margin-top: -11px;

		> * {
			margin: 2ex 0 0;
		}
	}

	&__links {
		list-style: none;
		margin: 2rem 0 0;
		padding: 0;
	}

	&__links-item {
		display: block;
		margin-top: 1.5rem;

		a {
			display: flex;
			color: #4A4A49 !important;
			font-size: 18px;
			align-items: center;
		}

		p {
			margin: 0;
		}

		&--download {}
		&--url {}
	}

	&__icon {
		width: 2.7rem;
		aspect-ratio: 1;
		margin-right: 1.2rem;
		flex-shrink: 0; 

		path {
			fill: $brand-three;
		}
	}
}


.resources-pagination {
	width: 100%;

	&__link {
		color: #4A4A49 !important;
		text-decoration: none;
		font-size: 15px;
		margin-right: 1ex;

		&--current {
			font-weight: 800;
		}

		&--prev {
			margin-right: 2ex;
		}

		&--next {
			margin-left: 2ex;
		}
	}
}