.form {
	textarea {
		width: 100%;
		min-height: 200px;
	}

	&__row {
		margin: 0 0 15px;

		&--half {
			width: 50%;
			float: left;
		}
	}

	&__label {
		display: block;
		margin: 0 0 5px;
		font-weight: 500;

		input[type="checkbox"],
		input[type="radio"] {
			position: relative;
			top: -2px;
			margin: 0 5px 0 0;
		}

		&--pad {
			position: relative;
			padding: 0 0 0 20px;

			input[type="checkbox"],
			input[type="radio"] {
				position: absolute;
				top: 4px;
				left: 0;
			}

			input[type="checkbox"] {
				top: 5px;
			}
		}

		.checkboxes & {
			font-weight: 300;
		}
	}

	&__text {
		border: 2px solid #ccc;
		padding: 14.5px 10px;
		width: 100%;
		max-width: 400px;

		&--xs {
			width: 75px;
		}

		&--sm {
			width: 125px;
		}

		&--md {
			width: 150px;
		}

		&--lg {
			width: 225px;
		}

		&--full {
			max-width: 100%;
		}

		&--tall {
			height: 145px;
		}

		&[readonly] {
			background: $superlightgrey;
			cursor: not-allowed;
		}
	}

	&__select {
		width: 100%;
		max-width: 400px;
		height: 58px;
		background: $white;
	}

	&__file {
		width: 100%;
		max-width: 400px;
	}

	&__toggle {
		max-height: 0;
		transition: 0.4s ease;
		overflow: hidden;
		opacity: 0;
		margin-top: -80px;

		&--active {
			max-height: 250px;
			opacity: 1;
			margin-top: 0;
		}
	}

	.ff-errors {
		color: #a80532;
	}

	.ff-errors {
		list-style-type: none;
		padding: 0;
	}

	.ff-has-errors {
		border-color: #a80532;
	}
}

.ff-form-errors p {
	color: #a80532;
}

.ff-form-success p {
	color: #009170;
}