.form-steps {
	list-style: none;
	padding: 0;
	font-size: 0;
	position: relative;
	margin: 0 -5px $gutter;
	@include media-breakpoint-up(lg) {
		margin: 0 -5px 10px;
	}

	&__step {
		width: (100 / 6) * 1%;
		font-size: $base-font-size;
		display: inline-block;
		background: $base-font-color;
		color: $white;
		border-left: 5px solid $white;
		border-right: 5px solid $white;
		text-align: center;
		font-weight: 700;
		padding: 8px 0;

		&--active {
			background: $brand-one--dark;

			@include media-breakpoint-down(md) {
				.form-steps__title {
					display: block;
					color: $brand-one--dark;
					position: absolute;
					bottom: -30px;
					left: 5px;
				}
			}
		}
	}

	@include media-breakpoint-down(md) {
		&__title {
			display: none;
		}
	}

	&__link {
		display: block;
		background: $brand-one;
		color: $white;
		text-decoration: none;
		margin: -8px 0;
		padding: 8px 0;
	}

	button {
		width: 100%;
		border: 0;
	}
}
