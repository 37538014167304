.header {
	position: relative;
	z-index: 500;
	background: $white;

	.container {
		position: relative;
	}

	.nav {
		line-height: 1;
		position: relative;

		&__list {
			&--mobile {
				background: $white;
				position: absolute;
				right: 0;
				width: calc(100vw - #{$gutter});
				@include media-breakpoint-up(sm) {
					width: 110%;
				}
			}
		}

		&__item {
			text-align: center;
			border-bottom: 1px solid $midgrey;
			margin: 0 0 4px;
			padding: 0 0 4px;

			@include media-breakpoint-up(lg) {
				border-bottom: 0;
				border-right: 1px solid $midgrey;
				margin: 0 8px 0 0;
				padding: 0 8px 0 0;

				&:last-of-type {
					padding: 0;
					border: 0;
					margin: 0;
				}
			}
			@include media-breakpoint-up(xl) {
				margin: 0 18px 0 0;
				padding: 0 18px 0 0;
			}
			@include media-breakpoint-up(xxl) {
				margin: 0 24px 0 0;
				padding: 0 24px 0 0;
			}

			&--dropdown {
				position: relative;
				@include media-breakpoint-up(xl) {
					padding: 0 36px 0 0;
				}
				@include media-breakpoint-up(xxl) {
					padding: 0 42px 0 0;
				}

				&::before {
					content: '';
					position: absolute;
					display: block;
					background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 8.25'%3E%3Cpath fill='rgba(155,155,155,.99)' d='M9.75 0L6 3.75 2.25 0 0 2.25 3.75 6 6 8.25 8.25 6 12 2.25 9.75 0'/%3E%3C/svg%3E") center no-repeat;
					width: 12px;
					height: 8.25px;
					right: 18px;
					top: 3px;
				}

				&:hover {
					&::after {
						content: '';
						display: block;
						position: absolute;
						padding: 40px;
						top: 100%;
						width: 200vw;
						left: -100vw;
					}

					.nav__dropdown {
						display: block;
					}
				}
			}
		}

		&__dropdown {
			padding: $gutter/4 0;

			.header__nav-item {
				margin: 0 0 $gutter/4;
			}

			@include media-breakpoint-up(md) {
				display: none;
				position: absolute;
				top: 200%;
				left: #{-$gutter};
				background: $white;
				text-align: left;
				padding: $gutter;
				width: 225%;
				z-index: 50;

				.nav__item {
					display: block;
					margin: 0 0 $gutter/2;
					font-size: 16px;
					border: 0;
				}

				.nav__link {
					font-family: $sans-serif;
					color: $brand-one;
					text-transform: none;

					&:hover {
						color: $black;
					}
				}
			}

			&--brand-three {
				.nav__link {
					color: $brand-three;
				}
			}
		}

		&__link {
			color: $grey;
			text-decoration: none;
			text-transform: uppercase;
			display: block;
			padding: 5px 0;
			@include media-breakpoint-up(lg) {
				padding: 0;
			}

			&:hover,
			&--active {
				color: $base-font-color !important;
			}

			&--mega {
				position: relative;
				padding: 0 18px 0 0;

				&::before {
					content: '';
					position: absolute;
					display: block;
					background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 8.25'%3E%3Cpath fill='rgba(155,155,155,.99)' d='M9.75 0L6 3.75 2.25 0 0 2.25 3.75 6 6 8.25 8.25 6 12 2.25 9.75 0'/%3E%3C/svg%3E") center no-repeat;
					width: 12px;
					height: 8.25px;
					right: 0;
					top: 6px;
				}

				&:hover {
					&::after {
						content: '';
						display: block;
						position: absolute;
						padding: 40px;
						top: 100%;
						width: 200vw;
						left: -100vw;
					}
				}
			}
		}
	}

	p {
		color: $grey;
		text-transform: uppercase;
	}

	&__tel {
		font-size: 24px;
		color: $base-font-color;
		font-weight: 400;
	}

	&__logo {
		max-width: 280px;

		.logo {
			&--dark {
				fill: $brand-one--dark;
			}

			&--light {
				fill: $brand-one;
			}
		}
	}
}
