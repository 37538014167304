.pad-box {
	display: block;
	color: $white;
	text-decoration: none;

	&__icon {
		text-align: center;
	}

	@include media-breakpoint-up(md) {
		display: flex;
		align-items: center;

		&__icon {
			margin: 0 $gutter 0 0;
		}

		&__content {
			flex: 1 1 auto;
		}
	}
}
