.modal {
	width: 100%;
	max-width: 640px;
	color: $base-font-color;
	position: fixed;
	top: 25%;
	left: 50%;
	transform: translate3d(-50%, 0, 0);
	opacity: 0;
	transition: opacity 0.35s ease-in-out;
	z-index: 1000;

	&__cover {
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(0, 0, 0, 0.8);
		width: 100%;
		height: 100%;
		opacity: 0;
		transition: opacity 0.15s ease-in-out;
		z-index: 1000;
	}

	&__close {
		position: absolute;
		top: -37px;
		right: -18px;
		font-size: 30px;
		color: $white;
	}

	&--open {
		overflow: hidden;

		.modal__cover {
			opacity: 1;
			transition: opcaity 0.15s ease-in-out;
		}

		.modal {
			opacity: 1;
			transition: opacity 0.35s ease-in-out;
		}
	}
}
