.applicantList {
	margin: 2rem 0 5rem;

	h2 {
		font-weight: 600;
		font-size: 18px;
	}

	h3 {
		font-size: 16px;
	}

	&__item {
		display: flex;
		position: relative;
		background-color: #f9f9f9;
		padding: 1rem;
		color: #700c24;
		text-decoration: underline;
		cursor: pointer;

		&--heading {
			margin-bottom: 0;
			background-color: #fff;
			color: #333;
			text-decoration: none;
		}

		&:nth-of-type(even) {
			background: #f1f1f1;
		}

		&:hover {
			text-decoration: none;
		}
	}

	&__column {
		width: 33.3333%;
		margin: 0;
		padding-right: 5rem;
	}

	&__link {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
	}
}
