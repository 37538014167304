.list {
	&--reset {
		margin: 0;
		padding: 0;
		list-style: none;
	}

	&--spaced {
		margin: $gutter 0;
		padding: 0;
		list-style: none;
	}

	&--inline {
		font-size: 0;

		> li {
			display: inline-block;
			font-size: $base-font-size;
		}
	}

	&--lined {
		> li {
			border-bottom: 1px solid $midgrey;
			padding: $gutter/2 0;
		}

		&--white {
			border-top: 1px solid $white;

			> li {
				border-bottom: 1px solid $white;
				padding: $gutter/2 0;
			}
		}
	}

	&__item {
		&--flex {
			display: flex;
			align-items: center;
		}

		&--spaced {
			margin: 0 0 10px;

			&--inline {
				margin: 0 10px 0 0;
			}
		}
	}

	&__icon {
		margin: 0 $gutter/2 0 0;

		img,
		svg {
			max-width: 100px;
		}

		svg {
			width: 100px;
		}

		&.tutor-images {
			flex-shrink: 0;
		}
	}

	&--phone {
		background: url("data:image/svg+xml,%3Csvg viewBox='0 0 15 21' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.927 2.27l-.89.442c-4.598 2.417 3.63 18.536 8.383 16.35l.845-.414-1.973-3.835-.892.438c-2.748 1.355-7.178-7.19-4.39-8.695l.873-.435-1.956-3.85zM2.25 1.158L4.72 0l3.512 6.903-2.367 1.174C5.398 9.18 7.74 13.58 8.73 13.653c.078-.05 2.32-1.162 2.32-1.162l3.57 6.94s-2.4 1.186-2.48 1.222c-.524.242-1.066.352-1.616.348C5.59 20.962-.026 11.756 0 5.728c.01-2.057.675-3.744 2.25-4.57z' fill='rgba(255,255,255,0.99)' fill-rule='evenodd'/%3E%3C/svg%3E%0A") left center / 17px no-repeat;
		padding-left: 35px !important;
	}

	&--email {
		background: url("data:image/svg+xml,%3Csvg viewBox='0 0 19 14' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0 14h18.667V0H0v14zM1.93 1.556h14.806l-7.403 6-7.403-6zm15.18 1.697v9.19H1.557v-9.19l7.777 6.304 7.778-6.304z' fill='rgba(255,255,255,0.99)' fill-rule='evenodd'/%3E%3C/svg%3E%0A") left center / 20px no-repeat;
		padding-left: 35px !important;
	}
}

* + .list--lined {
	border-top: 1px solid $midgrey;
}
