.breadcrumbs {
	padding: $gutter/4 $gutter/2;
	@include media-breakpoint-up(sm) {
		padding: $gutter/4 0;
	}

	a {
		text-decoration: none;
	}

}
