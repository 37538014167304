.list-columns {
	border-top: 2px solid $lightgrey;

	&--item {
		text-transform: capitalize;
		padding: 2rem;
		width: 50%;
	}

	@include media-breakpoint-up(lg) {
		display: flex;

		&--item {
			&:nth-of-type(2) {
				border-left: 2px solid $lightgrey;
			}
		}
	}
}
