.tabs {
	&__tab {
		font-size: 18px;
		padding: $gutter/4;
		text-align: center;
		transition: all $transition;
		cursor: pointer;
		border-bottom: 2px solid;
		display: inline-block;
		background: $superlightgrey;

		&--brand-three {
			border-bottom: 2px solid $brand-three;

			&.tabs__tab--active {
				position: relative;
				background: $brand-three;
				color: $white;
				border: 2px solid $brand-three;
			}
		}

		&--active {
			border: 2px solid;
			border-bottom: 0;
		}
	}
}
