.parsley {
	&-error {
		border-color: $brand-one;
	}

	&-errors-list {
		list-style: none;
		margin: 0;
		padding: 0;
		color: $brand-one;
	}
}
