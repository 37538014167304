.page-hero {
	border-top: 3px solid;

	&--brand-one {
		border-top-color: $brand-one;
	}

	&--brand-one--dark {
		border-top-color: $brand-one--dark;
	}

	&--brand-two {
		border-top-color: $brand-two;
	}

	&--brand-two--dark {
		border-top-color: $brand-two--dark;
	}

	&--brand-three {
		border-top-color: $brand-three;
	}

	&--brand-three--dark {
		border-top-color: $brand-three--dark;
	}

	img {
		display: block;
	}
}
