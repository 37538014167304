img,
.isvg {
	max-width: 100%;
	height: auto;
}

.js-hover {
	cursor: pointer;
}

.js-link {
	text-decoration: underline;

	&:hover {
		text-decoration: none;
	}
}

.js {
	.js-bg-img {
		visibility: hidden;
	}
}

.hidden {
	display: none;

	@include media-breakpoint-only(xs) {
		&--xs {
			display: none !important;
		}
	}
	@include media-breakpoint-only(sm) {
		&--sm {
			display: none !important;
		}
	}
	@include media-breakpoint-only(md) {
		&--md {
			display: none !important;
		}
	}
	@include media-breakpoint-only(lg) {
		&--lg {
			display: none !important;
		}
	}
	@include media-breakpoint-only(xl) {
		&--xl {
			display: none !important;
		}
	}
	@include media-breakpoint-only(xxl) {
		&--xxl {
			display: none !important;
		}
	}
}

.pad {
	@include pad();
}

.push {
	@include push();
}

.pull {
	@include pull();
}

.bg {
	&--white {
		background: $white;
	}

	&--lightgrey {
		background: $lightgrey;
	}

	&--superlightgrey {
		background: $superlightgrey;
	}

	&--superlightgrey-tint {
		background: rgba(0, 0, 0, .025);
	}

	&--brand-one {
		background: $brand-one;
		color: $white;

		a {
			color: $white;
		}
	}

	&--brand-one--dark {
		background: $brand-one--dark;
		color: $white;

		a {
			color: $white;
		}
	}

	&--brand-one--tint {
		background: rgba($brand-one, .05);
	}

	&--brand-two {
		background: $brand-two;
		color: $white;

		a {
			color: $white;
		}
	}

	&--brand-two--dark {
		background: $brand-two--dark;
		color: $white;

		a {
			color: $white;
		}
	}

	&--brand-two--tint {
		background: rgba($brand-two, .05);
	}

	&--brand-three {
		background: $brand-three;
		color: $white;

		a {
			color: $white;
		}
	}

	&--brand-three--dark {
		background: $brand-three--dark;
		color: $white;

		a {
			color: $white;
		}
	}

	&--brand-four {
		// background: $brand-four;
	}

	&--brand-four--dark {
		background: $brand-four--dark;
	}
}

.hidden {
	@include hidden();
}

.text {
	@include text-align();
}

.img {
	&--block {
		display: block;
	}

	&--sm {
		max-width: 120px;
	}
}
